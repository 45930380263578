@use './breakpoints.scss' as breakpoints;
@use './variables.scss' as vars;
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/gilroy');

/* reset browser default */
html,
body,
div,
span,
applet,
object,
iframe,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  vertical-align: baseline;
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 300;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include breakpoints.tablet {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include breakpoints.mobile {
    // width < 900?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include breakpoints.large {
    font-size: 75%; //1rem = 12, 12/16
  }
}

.back_grey {
  background-color: #f7f7f7;
}

a {
  &,
  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
}

button {
  padding: 5px 10px;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}
/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  font-weight: normal;
  text-align: left;
}
/* remove possible quote marks (") from <q> & <blockquote> */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
blockquote,
q {
  quotes: '' '';
}

img {
  max-width: 100%;
}

.btn_lg {
  font-size: 16px;
  padding: 10px 20px;
}

.button-primary {
  background-color: vars.$color-primary;
  color: vars.$color-light;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: row-reverse;

  &-cta {
    text-transform: uppercase;
  }

  &:disabled {
    background-color: #6170cf6f;
  }
}
.button-secondary {
  border: 1px solid vars.$color-primary;
  color: vars.$color-primary;
  background-color: transparent;
  font-weight: 700;
  text-transform: capitalize;

  &:disabled {
    opacity: 0.5;
  }
}

.primary {
  color: vars.$color-primary;
}

.heading-primary {
  text-align: center;
  margin-bottom: 50px;
  h1 {
    font-size: vars.$font-size-lg;
    font-weight: 700;
    color: vars.$color-dark;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    color: vars.$color-primary;
  }
}

.scroll {
  background-color: vars.$color-primary !important;
  bottom: 100px !important;
  width: 60px !important;
  height: 60px !important;
  font-size: 25px;
}

.page__top {
  position: relative;
  margin-bottom: 25px;
  span {
    font-size: 25px;
    cursor: pointer;
  }

  h2 {
    font-size: 20px;
    color: vars.$color-primary;
    font-weight: 700;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    color: vars.$color-dark;
    font-size: 16px;
    text-align: center;
  }
}

.desk__hidden {
  display: none;
  @include breakpoints.tablet {
    display: block;
  }
}

.mobile__hidden {
  @include breakpoints.tablet {
    display: none;
  }
}

.profile__footer {
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 50px;

  p {
    text-align: center;
    font-size: 12px;
    color: #717171;
    font-weight: 700;
  }
}

.cart__header {
  font-size: 20px;
  margin-bottom: 15px;
}

.cart_loading {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #ffffff75;
}

.cart__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 50px;
  background-color: #f7f7f7;
}

.coupon_input {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  gap: 10px;

  button {
    background-color: #000;
    color: #fff;
    height: 50px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 80%;
    padding: 0 10px;
    border: none;
  }
}

.error-text {
  font-size: 12px;
  color: vars.$color-error;
}

.valid-text {
  font-size: 12px;
  color: vars.$color-success;
}

.total__price {
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  span {
    font-weight: 400;
    font-size: 23px;
  }
}

// Remove later
.formGroup {
  padding: 0 5px;
  label {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
    color: #717171;

    span {
      color: vars.$color-error;
    }
  }

  div {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border: 1px solid #717171;
    border-radius: 12px;

    input {
      height: 40px;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
    }

    textarea {
      height: 200px;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      resize: none;
    }
  }

  small {
    font-size: 12px;
    color: vars.$color-error;
    visibility: hidden;
  }
}

.errorClass {
  @extend .formGroup;

  div {
    border: 1px solid vars.$color-error;
  }

  small {
    visibility: visible;
  }
}

.design_info {
  padding: 20px;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.button-error {
  background-color: vars.$color-error !important;
  border: none !important;
  color: #fff;
}

.button-cancel {
  border: 1px solid #757575;
  color: #757575;
}

.empty-state {
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  max-width: 400px;
  margin: 0 auto;

  &-title {
    font-weight: 600;
    font-weight: 20px;
    text-align: center;
    font-size: 20px;
  }

  &-icon {
    max-width: 150px;
  }

  &-subtitle {
    text-align: center;
    font-size: 14px;
    color: #717171;
  }

  button {
    background-color: vars.$color-primary;
    color: vars.$color-light;
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}
