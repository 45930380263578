.page {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 450px;
  transform: translate(-50%, -50%);

  button {
    margin: 0;
    flex-direction: row;
    gap: 10px;
  }
}

.header {
  font-size: 50px;
  font-weight: 700;
  color: #757575;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  margin-bottom: 30px;
}
