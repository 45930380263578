@use '../../../variables.scss' as vars;

.formGroup {
  margin-bottom: 15px;
  label {
    font-weight: 700;
    font-size: 14px;
    color: #717171;

    span {
      color: vars.$color-error;
    }
  }

  div {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    border: 1px solid #717171;
    border-radius: 4px;

    input {
      height: 40px;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
    }

    textarea {
      height: 200px;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      resize: none;
    }
  }

  small {
    font-size: 12px;
    color: vars.$color-error;
    visibility: hidden;
  }

  article {
    text-align: right;
    button {
      padding: 0;
      font-size: 18px;
      color: vars.$color-primary;
    }
  }
}

.errorClass {
  @extend .formGroup;

  div {
    border: 1px solid vars.$color-error;
  }

  small {
    visibility: visible;
  }
}
