@use '../../variables.scss' as vars;

.page {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.logoBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 80px;
  }
}

.header {
  text-align: center;

  h1 {
    font-weight: 700;
    font-size: 30px;
    color: #000;
  }

  h3 {
    color: vars.$color-primary;
    font-size: 16px;
  }
}

.signIn__btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 30px auto;
  background-color: vars.$color-primary;
  font-weight: 700;
  color: #fff;
  padding: 10px 20px;
}

form {
  width: 100%;
}

.info {
  text-align: center;

  a {
    font-weight: 700;
    margin-left: 5px;
    color: vars.$color-primary;
  }
}

.altSignup {
  display: flex;
  flex-direction: column;
  gap: 25px;

  button {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    border: 1px solid #000;
    margin: auto;
  }
}

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 30px;

  span {
    width: 40%;
    height: 1px;
    background-color: #000;
  }

  small {
    text-align: center;
    width: 20%;
  }
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 12px;
  &:is(:link, :visited, :active, :hover) {
    color: vars.$color-primary;
  }
}

.backToLogin {
  cursor: pointer;
  &:is(:link, :visited, :hover, :active) {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    font-size: 14px;
    color: #757575;
    font-weight: 500;
  }
}

.foundUser {
  &__info {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.introBackground {
  background-color: vars.$color-primary;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    color: #fff;
    position: absolute;
    bottom: 20px;
    margin: auto;
    text-align: center;
  }

  img {
    width: 150px;
  }
}
