// Colors
$color-dark: #272d4e;
$color-light: #f5f6f1;
$color-black: #000;
$color-grey: #e7e7e7;
$color-grey-light: #e7e7e770;

$color-primary: #6170cf;
$color-error: #d32752;
$color-warning: #f7e19998;
$color-success: #1ac886;

// spacing
$base-padding: 0.75rem;
$base-margin: 0.75rem;

// borders
$base-border-thickness: 1px;
$base-border-radius: 0.5rem;

// box-shadow
$base-box-shadow: 0 0.2rem 2rem rgba(0, 0, 0, 0.15);

// font sizes
$base-font-size: 1.2rem;
$font-size-sm: $base-font-size * 0.75;
$font-size-lg: $base-font-size * 2.5;
$font-size-xl: $base-font-size * 4;
$font-size-xxl: $base-font-size * 5;

// grid
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 2rem;
$gutter-horizontal: 3rem;

@mixin absCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
