@mixin tablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 428px) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}
